//モーダル系JS

$(function () {
  $('.popup-modal').magnificPopup({
    type: 'inline',
    preloader: false,
    closeOnBgClick: false,
    closeBtnInside: false,
    // showCloseBtn: false,
    overflowY: 'scroll'
  });
  //閉じるリンクの設定
  $(document).on('click', '.popup-modal-dismiss', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

});